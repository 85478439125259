'use strict';

$(function() {
	var disableForm = function()
	{
		$('form#contact input[type!=submit]').each(function(){
			$(this).attr('disabled', 'disabled');
		});
		$('form#contact textarea').attr('disabled', 'disabled');
	};

	var enableForm = function()
	{
		$('form#contact input[type!=submit]').each(function(){
			$(this).removeAttr('disabled');
		});
		$('form#contact textarea').removeAttr('disabled');
	};

	var clearForm = function()
	{
		$('form#contact input[type!=submit]').each(function(){
			$(this).val('');
		});
		$('form#contact textarea').val('');
	};


	$('form#contact').on('submit', function(e) {
		e.preventDefault;
		var data = $(this).serialize();


		disableForm();

		$.ajax({
			url: CONTACT_SUBMIT_URL,
			data: data,
			method: 'post',
			dataType: 'json',
			success: function (response) {
				if (response.status == 'success') {
					alert('Dziękujemy, wiadomość została wysłana');
				} else {
					alert('Przepraszamy, wystąpił błąd');
				}
			},
			error: function(response) {
				alert('Przepraszamy, wystąpił błąd');
			},
			complete: function(response) {
				enableForm();
				clearForm();
			}
		});
		return false;
	});
});
